<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CCard>
			<CCardBody>
				<TheTableHeader
					:header="'FORMULIR PENGATURAN'"
					:subHeader="'DATA PERUSAHAAN DI DATA CENTER.'"
				/>
			</CCardBody>
		</CCard>
		<CRow>
			<CCol sm="6">

				<CCard>
					<CCardHeader>
						<strong>Form {{id > 0 ? 'Perubahan' : 'Penambahan'}}</strong> Data Perusahaan. {{resp}}
					</CCardHeader>
					<CCardBody>
						<CForm>
							<CInput
								type="text"
								description=""
								label="ID Company"
								v-model="id"
								plaintext
								readonly
							/>
							<CInput
								type="text"
								description=""
								label="End User"
								placeholder="Masukkan nama user.."
								v-model="name"
							/>
							<CInput
								type="text"
								description=""
								label="ID CRM"
								v-model="crm_id"
								required
							/>
							<CInput
								type="text"
								description=""
								label="ID SA"
								v-model="id_sa"
								required
							/>
							<CTextarea
								rows="3"
								description=""
								label="Alamat"
								placeholder="Masukkan alamat perusahaan.."
								v-model="address"
								required
							/>
							<CInput
								type="text"
								description=""
								label="Nama Perusahaan"
								placeholder="Masukkan nama perusahaan.."
								v-model="email"
								required
							/>
							<CInput
								type="number"
								description=""
								label="Telpon"
								placeholder="Masukkan nomor telpon perusahaan.."
								v-model="phone"
								required
							/>
							<p><small>minimal 7 karakter</small></p>
						</CForm>
					</CCardBody>
					<CCardFooter>
						<CButton type="submit" size="sm" color="success" @click="id === '0' ? createCompany() : updateCompany()"><CIcon name="cil-check-circle"/> Save</CButton> &nbsp;&nbsp;
						<CButton type="reset" size="sm" color="danger" @click="resetForm()"><CIcon name="cil-ban"/> Clear</CButton> &nbsp;&nbsp;
						<CButton type="cancel" size="sm" color="dark" @click="goBack()"><CIcon name="cil-arrow-left"/> Cancel</CButton>
					</CCardFooter>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'CompanyManagement',
	components: { TheTableHeader },
	data () {
		return {
			id: this.$route.params.id,
			crm_id: '',
			id_sa: '',
			name: '',
			email: '',
			address: '',
			phone: '',
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Perusahaan',
          href: '#/settings/companies'
        }, 
        {
          text: 'Manajemen Perusahaan'
        }
      ],
		}
	},
	paginationProps: {
		align: 'center',
		doubleArrows: false,
		previousButtonHtml: 'prev',
		nextButtonHtml: 'next'
	},
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		goBack(){
			this.$router.go(-1);
    },
		getBadge (status) {
			return status === 'Active' ? 'success'
				: status === 'Inactive' ? 'secondary'
					: status === 'Pending' ? 'warning'
						: status === 'Banned' ? 'danger' : 'primary'
		},
		createCompany() {
			let self = this;
			return http.post('/companies', {
				company_name: self.name,
				crm_id: self.crm_id,
				id_sa: self.id_sa,
				email: self.email,
				address: self.address,
				phone: self.phone,
				isActive: 1
			})
			.then(function () {
				self.toast('Perusahaan Berhasil Ditambahkan.', 'info')
				self.$router.go(-1);
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
		updateCompany() {
			let self = this;
			return http.put('/companies/' + self.id, {
				company_name: self.name,
				crm_id: self.crm_id,
				id_sa: self.id_sa,
				email: self.email,
				address: self.address,
				phone: self.phone
			})
			.then(function () {
				self.toast('Perusahaan Berhasil Diubah.', 'info')
				self.$router.go(-1);
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
		resetForm() {
			let self = this;
			self.name = '';
			self.crm_id = '';
			self.id_sa = '';
			self.address = '';
			self.email = '';
			self.phone = '';
		},
		getCompany(id) {
			let self = this;
			if (id !== '0') {
				return http.get('/companies/' + id)
				.then(function (response) {
					self.name = response.data.data.company_name;
					self.crm_id = response.data.data.crm_id;
					self.id_sa = response.data.data.id_sa;
					self.address = response.data.data.address;
					self.email = response.data.data.email;
					self.phone = response.data.data.phone;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	mounted: function(){
		this.getCompany(this.id);
	}
}
</script>